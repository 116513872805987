
import CompareBox from "@/components/compare/CompareBox"
import CompareSidebar from "@/components/compare/CompareSidebar"
import LAYER_TYPES from "@evercam/shared/constants/layerTypes"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCompareStore } from "@/stores/compare"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import RightSidebarContent from "@/components/portals/RightSidebarContent"

export default {
  name: "Compare",
  components: {
    CompareBox,
    CompareSidebar,
    RightSidebarContent,
  },
  meta: {
    pageId: AnalyticsEventPageId.Compare,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, $moment, app, route }) {
    const snapshotStore = useSnapshotStore()
    const cameraStore = useCameraStore()
    snapshotStore.reset()
    const compareStore = useCompareStore()
    const routeCameraExid = camelizeKeys(route.params)?.cameraExid

    if (!app.$permissions.user.can.access.compare()) {
      return redirect(cameraStore.cameraRoute)
    }

    const shouldUpdateSnapshots =
      !snapshotStore.cameraExid ||
      snapshotStore.cameraExid !== routeCameraExid ||
      !snapshotStore.latestSnapshotTimestamp ||
      !snapshotStore.oldestSnapshotTimestamp ||
      !$moment(snapshotStore.latestSnapshotTimestamp).isValid() ||
      !$moment(snapshotStore.oldestSnapshotTimestamp).isValid()

    if (shouldUpdateSnapshots) {
      await snapshotStore.updateLatestAndOldestSnapshots(routeCameraExid)
    }

    if (
      !compareStore.afterTimestamp ||
      !$moment(compareStore.afterTimestamp).isValid()
    ) {
      compareStore.afterTimestamp = snapshotStore.latestSnapshotTimestamp
    }
    if (
      !compareStore.beforeTimestamp ||
      !$moment(compareStore.beforeTimestamp).isValid()
    ) {
      let nearestMilestone
      try {
        nearestMilestone = await EvercamApi.layers.getLayer(routeCameraExid, {
          timestamp: compareStore.afterTimestamp,
          layerType: LAYER_TYPES.MILESTONE,
        })
      } catch (error) {
        console.error(
          "Unable to retrieve milestone layers for camera",
          routeCameraExid,
          error
        )
      }
      compareStore.beforeTimestamp =
        nearestMilestone?.startAt || snapshotStore.oldestSnapshotTimestamp
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.compare"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useSnapshotStore,
      useCompareStore,
      useCameraStore,
      useLayoutStore
    ),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.layoutStore.enableRightSidebar()
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
    this.compareStore.$reset()
  },
}
